<template>
  <default>
    <div class="tw-mb-4 c-blue-text">
      <v-chip-group mandatory active-class="primary">
        <!-- Dashboard chip hidden on small screens -->
        <v-chip active-class="false" :to="{ name: 'Dashboard' }">
          Dashboard
        </v-chip>
        <span class="tw-mx-2 mt-2"> > </span>

        <v-chip active-class="false" :to="{ name: 'Products' }">
          Products
        </v-chip>
        <span class="tw-mx-2 mt-2"> > </span>

        <v-chip :to="{ name: 'WealthBuilderPlan' }">
          {{ $options.name }}
        </v-chip>
      </v-chip-group>
    </div>

    <!-- Main Card for Wealth Builder Plan Calculator -->
    <v-card elevation="2"
      class="main-card tw-px-4 md:tw-px-8 tw-py-6 md:tw-py-8 tw-rounded-lg tw-shadow-lg tw-bg-white">
      <!-- Title Section with Icon -->
      <div class="tw-flex tw-items-center tw-mb-6">
        <img src="/images/icons/top-icon.png" alt="Wealth Builder Icon" class="tw-w-10 tw-h-10 tw-mr-3" />
        <h2 class="tw-text-2xl tw-font-semibold c-blue-text">Wealth Builder Plan Calculator</h2>
      </div>

      <!-- Form Container with conditional shadow for larger screens -->
      <div
        class="form-container tw-w-full tw-flex tw-flex-wrap tw-py-4 md:tw-py-6 tw-bg-gray-50 tw-rounded-md tw-shadow-inner lg:tw-shadow-lg">
        <keep-alive>
          <component :is="current" :wealthbuilder="wealthbuilder" :benefits="benefits"
            :productCode="productData.prodCode" :optionCode="productData.optionCode" :terms="terms"
            :quoteSummaryData="quoteSummaryData" :frequency="Wealthfrequency" @fetchBenefits="fetchBenefits">
          </component>
        </keep-alive>
      </div>
    </v-card>
  </default>
</template>

<script>
import Default from '../../layouts/Default';
import CardTitle from '../../components/CardTitle';
import BackButton from '../../components/BackButton';
import WealthClientQuotation from '../../components/rfq/wealthbuilder/Quote';
import ProductMixin from '@/mixins/ProductMixin';
import savedQuoteMixin from '../../mixins/savedQuoteMixin';

export default {
  name: 'WealthBuilderPlan',
  metaInfo: {
    title: 'RFQ - Wealth Builder Plan',
  },
  components: {
    BackButton, CardTitle, Default, WealthClientQuotation,
  },
  mixins: [ProductMixin, savedQuoteMixin],
  data() {
    return {
      wealthbuilder: true,
      current: WealthClientQuotation,
      productData: { prodCode: 2020196, optionCode: 2020215 },
      benefits: [],
      productOptions: [],
      terms: [],
      quoteSummaryData: {},
      termLimit: {
        min: 5,
        max: 100,
      },
      Wealthfrequency: ['Annually', 'Single Premium'],
    };
  },
  mounted() {
    this.checkSavedQuote('wealth');
    this.fetchProductOptions();
    this.fetchBenefits();
    this.fetchTerms(this.scanObject(this.productOptions, '0.optionCode', 2021217));
  },
};
</script>

<style scoped>
.main-card {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 2rem;
}

.form-container {
  background-color: #f9fafb;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.05);
}

.benefits-section {
  padding: 1.5rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.benefits-section .tw-shadow-sm {
  transition: box-shadow 0.2s ease;
}

.benefits-section .tw-shadow-sm:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.v-checkbox__label {
  font-size: 0.9rem;
  color: #374151;
}
</style>
